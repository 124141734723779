<template>
  <div class="container">

    <overview :achId="achId" />
    <vendor-submitted />
    <verification-call-info v-if="this.status === 'Transferred-Waiting Finance'" />
    <!-- v-if="[ 'Transferred-Waiting Finance', 'Transferred-Waiting Authorization' ].includes(this.status) && ['AchAdministrator'].includes(this.curUserType)" -->
    <financial-information
      v-if="['AchAdministrator'].includes(this.curUserType)"
      :achId="achId"
      @show-bank-info="showBankInfoSuccess"
      @image-viewed="imageViewedSuccess"
    />
    <transfer-component
      v-if="showTransfer"
      @achTransferSuccess="refresh"
    />
    <audit-trail />

  </div>
</template>

<script>

import Overview from '@/pages/ach-request/sections/Overview';
import TransferComponent from '@/pages/ach-request/sections/TransferComponent';
import VendorSubmitted from '@/pages/ach-request/sections/VendorSubmitted';
import FinancialInformation from '@/pages/ach-request/sections/FinancialInformation';
import VerificationCallInfo from '@/pages/ach-request/sections/VerificationCallInfo';
import AuditTrail from '@/pages/ach-request/sections/AuditTrail';

export default {
  name: "AchRequestTransfer",
  components: {
    Overview,
    VendorSubmitted,
    FinancialInformation,
    VerificationCallInfo,
    AuditTrail,
    TransferComponent,
  },
  props: {
    achId: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    showTransfer() {
      let value = false;
      if ([ 'AchAdministrator' ].includes(this.curUserType)) {
        value = true;
      } else if (this.$store.state.ach.achData.department) {
        let requestDeptId = this.$store.state.ach.achData.department.id;
        let userDeptId = this.$store.state.users.curUserData.departmentId;
        if (userDeptId === requestDeptId) {
          value = true;
        }
      }
      return value;
    },
  },
  methods: {
    showBankInfoSuccess() {
      this.$emit('achApiCallSuccess');
    },
    imageViewedSuccess() {
      this.$emit('achApiCallSuccess');
    },
    refresh() {
      this.$emit('achApiCallSuccess');
    },
  },
};

</script>
