<template>

  <div class="columns is-mobile">
    <div class="label-field column is-6">
      <div class="field">
        <dropdown
          id="transfer-dropdown"
          v-model="transferDropdownValue"
          name="Transfer"
          placeholder="Select a department"
          value-key="id"
          text-key="name"
          :options="departments"
          required
        />
      </div>
    </div>
    <div class="column is-6">
      <button
        class="button is-primary margin-top-sm"
        :class="{ 'is-loading': transferIsLoading }"
        @click.prevent="transferRequest"
      >
        Transfer request
      </button>
    </div>
  </div>

</template>

<script>

import { Dropdown } from '@phila/phila-ui';

export default {
  name: 'TransferComponent',
  components: {
    Dropdown,
  },
  data() {
    return {
      transferIsLoading: false,
      transferDropdownValue: null,
      AchID: null,
    };
  },
  computed: {
    departments() {
      let values;
      if (this.$store.state.departments.departments) {
        let finance = {
          id: null,
          name: 'I do not know',
        };
        values = [ ...this.$store.state.departments.departments, finance ];
      }
      return values;
    },
  },
  created () {
    this.AchID = this.$route.params.id;
  },
  methods: {
    async transferRequest() {
      this.$emit('tranferStarted');
      let formData = {
        humanReadableId: this.AchID,
        departmentId: this.transferDropdownValue,
      };
      this.transferIsLoading = true;
      let data;
      if (this.transferDropdownValue) {
        data = await this.$store.dispatch('ach/submitAchTransferRequestToOther', formData);
      } else {
        data = await this.$store.dispatch('ach/submitAchTransferRequestToFinance', formData);
      }
      if (data && data.status == 200) {
        this.transferIsLoading = false;
        this.$emit('achTransferSuccess');
        // this.$emit('achApiCallSuccess');
        // this.$router.push({ name: 'ach-request' });
      } else {
        this.$defaultResponseController(data);
        this.transferIsLoading = false;
      }
    },
  },
};
</script>
